import { BrandButton } from "@components/reusables/brandButton";
import { IInformationSection } from "@interface/propsInterface";
import { ISProgramSectionProps } from "@interface/styledComponentInterface";
import {
  color,
  fontSize,
  fontWeight,
  screenBreakPoint,
} from "@styles/styleVariables";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { ButtonEnum } from "src/enums/buttonsEnum";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  fadeIn,
  parentAnimation,
  pushItemDown,
  pushItemLeft,
  pushItemRight,
} from "@styles/animations";
import { DayEnum } from "src/enums/dayEnum";

export const ScheduleDay = ({ day, classes }: { day: string; classes: [] }) => {
  return (
    <SScheduleDay>
      <h2 className="class-day">{day.toLocaleUpperCase()}</h2>
      {classes.map((c: any) => (
        <div className="class" key={c?.id ?? ""}>
          <p className="class-name">{c.name ?? ""}</p>
          <div className="class-time">
            <p>
              {new Date(`January 01, 2024 ${c.starttime}`).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              -{" "}
              {new Date(`January 01, 2024 ${c.endtime}`).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          </div>
          {c?.location && (
            <div className="class-location">
              <p className="class-location-header">Location: </p>
              <p className="class-location-content">{c?.location ?? ""}</p>
            </div>
          )}
          {c?.age?.length > 0 && (
            <div className="class-ages">
              <p className="class-ages-header">Ages:</p>
              {c?.age?.map((r: any) => (
                <p className="class-ages-content">{r.age ?? ""}</p>
              ))}
            </div>
          )}
          {c?.rank?.length > 0 && (
            <div className="class-ranks">
              <p className="class-ranks-header">Ranks:</p>
              {c?.rank?.map((r: any) => (
                <p className="class-ranks-content">{r.rank ?? ""}</p>
              ))}
            </div>
          )}
        </div>
      ))}
    </SScheduleDay>
  );
};

const SScheduleDay = styled(motion.div)`
  min-width: 20rem;
  margin: 1rem;

  .class-day {
    margin-bottom: 2rem;
    width: 100%;
    background-color: ${color.oxfordBlue};
    color: ${color.sunglow};
    font-weight: ${fontWeight.semiBold};
    padding: 1rem;
  }

  .class {
    margin-top: 1rem;
    background-color: ${color.culture};
    padding: 1rem;

    .class-name: {
      font-weight: ${fontWeight.regular};
    }

    .class-time {
      font-size: ${fontSize.sm};
      font-weight: ${fontWeight.light};
    }

    .class-location {
      margin-top: 0.5rem;
      .class-location-content {
        font-size: ${fontSize.xs};
        font-weight: ${fontWeight.light};
      }
      .class-location-header {
        font-size: ${fontSize.xs};
        font-weight: ${fontWeight.regular};
      }
    }

    .class-ages {
      margin-top: 0.5rem;
      .class-ages-content {
        font-size: ${fontSize.xs};
        font-weight: ${fontWeight.light};
      }
      .class-ages-header {
        font-size: ${fontSize.xs};
        font-weight: ${fontWeight.regular};
      }
    }

    .class-ranks {
      margin-top: 0.5rem;
      .class-ranks-content {
        font-size: ${fontSize.xs};
        font-weight: ${fontWeight.light};
      }
      .class-ranks-header {
        font-size: ${fontSize.xs};
        font-weight: ${fontWeight.regular};
      }
    }
  }
`;
