import InstructorInformation from "@templates/instructor-information-template";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { DayEnum } from "src/enums/dayEnum";
import styled from "styled-components";
import { ScheduleDay } from "./scheduleDay";

export const ScheduleContent = () => {
  const query = useStaticQuery(graphql`
    query scheduleQuery {
      scheduleQuery: allStrapiSchedule(
        sort: { fields: starttime, order: ASC }
      ) {
        nodes {
          day
          name
          starttime
          endtime
          location
          age {
            age
          }
          rank {
            rank
          }
          id
        }
      }
    }
  `);

  const scheduleData = query.scheduleQuery.nodes;
  const sunday = scheduleData.filter((s: any) => s.day == DayEnum.Sunday);
  const monday = scheduleData.filter((s: any) => s.day == DayEnum.Monday);
  const tuesday = scheduleData.filter((s: any) => s.day == DayEnum.Tuesday);
  const wednesday = scheduleData.filter((s: any) => s.day == DayEnum.Wednesday);
  const thursday = scheduleData.filter((s: any) => s.day == DayEnum.Thursday);
  const friday = scheduleData.filter((s: any) => s.day == DayEnum.Friday);
  const saturday = scheduleData.filter((s: any) => s.day == DayEnum.Saturday);

  return (
    <SScheduleContent>
      <ScheduleDay day={DayEnum.Sunday} classes={sunday}></ScheduleDay>
      <ScheduleDay day={DayEnum.Monday} classes={monday}></ScheduleDay>
      <ScheduleDay day={DayEnum.Tuesday} classes={tuesday}></ScheduleDay>
      <ScheduleDay day={DayEnum.Wednesday} classes={wednesday}></ScheduleDay>
      <ScheduleDay day={DayEnum.Thursday} classes={thursday}></ScheduleDay>
      <ScheduleDay day={DayEnum.Friday} classes={friday}></ScheduleDay>
      <ScheduleDay day={DayEnum.Saturday} classes={saturday}></ScheduleDay>
    </SScheduleContent>
  );
};

const SScheduleContent = styled.div`
  min-height: 50vh;
  display: flex;
  overflow-x: scroll;
  justify-content: flex-start;
`;
